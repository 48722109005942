.androidContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.downloadContainer {
  position: relative;
  max-width: 550px;
  color: #a9b1b3;
  font-size: 14px;
  width: 100%;
  padding-top: 80px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tips {
    position: absolute;
    right: 8px;
    top: 10px;
    background-color: #1480c5;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 6px;
  }
  .tips::after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #1480c5;
    position: absolute;
    top: -16px;
    right: 10px;
  }
  .title {
    font-size: 30px;
    font-size: bold;
    margin: 10px 0;
    color: #000000;
  }
  .logo {
    width: 150px;
    height: 150px;
    border-radius: 20px;
  }
  .downloadButton {
    width: 180px;
    margin: 20px 0;
  }
  .contentTitle {
    font-size: 18px;
    color: #000000;
  }
  .contentContainer {
    width: 100%;
    padding: 15px;
  }
  .separator {
    width: 90%;
    height: 1px;
    background-color: #eeeeee;
    margin: 30px;
  }
}
