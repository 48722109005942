@import "~antd/dist/antd.css";
.image-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-container img {
  max-width: 100% !important;
}
.ant-carousel .slick-dots.carousel-dot li button {
  background-color: black;
}

.ant-carousel .slick-dots.carousel-dot li.slick-active button {
  background-color: black;
}

.title {
  margin-left: 25px;
}
