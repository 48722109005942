.footer-container {
  font-size: 14px;
  display: inline-block;
  padding: 10px 50px;
  border-top: 1px solid #b7b7ba;
  color: #6e6e73;
  text-align: center;
  margin-bottom: 10px;
}

.footer-link:hover {
  text-decoration: underline;
}
