@import "~antd/dist/antd.css";

.header {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-right: 12px;
}

.headerLogo {
  width: 120px;
  height: 60px;
}

.download {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.downloadButton {
  margin: 0 15px;
}

.downloadPopover {
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadItem {
  margin: 5px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.miniProgramCode {
  width: 140px;
  height: 140px;
}
